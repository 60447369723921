import React, { useState, useCallback } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import urlBuilder from "@sanity/image-url"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import SEO from "../components/seo"

export const query = graphql`
  {
    sanityPortfolio {
      title
      portfolioImages {
        imageUpload {
          asset {
            metadata {
              dimensions {
                width
                height
              }
            }
            id
          }
        }
        imageDescription
      }
    }
  }
`

export default ({ data, pageContext }) => {
  const portfolioImages = data.sanityPortfolio.portfolioImages

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const urlFor = (source) =>
    urlBuilder({ projectId: "yk6mnr40", dataset: "production" }).image(source)

  const galleryMap = portfolioImages.map((image) => ({
    src: urlFor(image.imageUpload.asset.id).auto("format").width(1600).url(),
    srcSet: [
      // urlFor(image.asset)
      //   .width(1600)
      //   .url() + ` 1600w`,
      urlFor(image.imageUpload.asset.id).auto("format").width(800).url() +
        ` 800w`,
      urlFor(image.imageUpload.asset.id).auto("format").width(400).url() +
        ` 400w`,
    ],
    sizes: ["(min-width: 800px) 400px, 100vw"],
    width: image.imageUpload.asset.metadata.dimensions.width,
    height: image.imageUpload.asset.metadata.dimensions.height,
    title: image.imageDescription,
    alt: image.imageDescription,
    // alt: image.seets.metadata.title,
  }))

  return (
    <Layout>
      <SEO
        title={data.sanityPortfolio.title}
        description="Photography by Chris McGloin"
      />
      <div
        css={{
          // mixBlendMode: "difference",
          // background: "#FAF8F6",
          overflow: "hidden",
        }}
      >
        {/* <div css={pageContentStyle}>
          <Heading as="h1">{page.title}</Heading>
        </div> */}

        <Gallery
          photos={galleryMap}
          direction="column"
          onClick={openLightbox}
        />

        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                styles={{
                  view: () => ({
                    textAlign: "center",
                    "& > img": {
                      display: "initial",
                      maxHeight: "100vh",
                    },
                  }),
                }}
                currentIndex={currentImage}
                views={galleryMap.map((image) => ({
                  ...image,
                  // srcset: image.srcSet,
                  caption: image.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Layout>
  )
}
